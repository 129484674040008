import React, { useState } from 'react'
import Image from 'next/image'
import Link from 'next/link'

import Likes from './likes'
import Tippy from '@tippyjs/react'
import { abbreviateNumber } from '../helpers/number-to-suffix'
import { useDispatch } from 'react-redux'
import { buyModalShow } from '../redux/counterSlice'
import { ImageWithPlaceholderFade } from './imageWithPlaceholderFade'
import { BlocksLoader } from './placeholder/blocks-loader'

export const ResultCard = ({
  links,
  username,
  name,
  gender,
  profile_image,
  tags_list,
  views,
}) => {
  const [showLoader, setShowLoader] = useState(false)

  return (
    <article
      className='relative animate-[fadeIn_.8s_linear]'
      onClick={() => setShowLoader(true)}
    >
      {showLoader && (
        <div className='absolute flex flex-col items-center justify-center rounded-2xl t-0 l-0 h-full w-full z-10 overflow-hidden bg-[#ffffffee] dark:bg-[var(--darkmode-bg-tr)]'>
          <BlocksLoader />
          <span className='text-jacarta-300'>Loading...</span>
        </div>
      )}
      <div className='dark:bg-jacarta-700 dark:border-jacarta-700 border-jacarta-100 rounded-2.5xl block border bg-white p-[1.1875rem] transition-shadow hover:shadow-lg'>
        <figure className='relative !h-[230px] !w-[100%]'>
          {/* Model Image & Link */}
          <Link href={`/model/${username}`}>
            <ImageWithPlaceholderFade
              src={`${profile_image}?class=searchpageresultcarddesk`}
              fallbackSrc='/images/avatars/not_found.png'
              srcSet={`
              ${profile_image}?class=searchpageresultcarddesk 900w,
              ${profile_image}?class=searchpageresultcardtab  626w,
              ${profile_image}?class=searchpageresultcardmob  460w, 
              `}  
              sizes='(min-width: 1200px) 230px,
              (min-width: 768px) and (max-width: 1199px) 235px,
              (max-width: 767px) 178px'
              alt={`user ${username}`}
              showImage
              rounded={true}
            />
          </Link>

          {/*  External Site Link */}
          <div className='absolute left-3 -bottom-3'>
            <div className='flex -space-x-2'>
              {links?.map((link) => {
                const { id, site, subscribe_price, profile_url } = link
                return (
                  <Link legacyBehavior key={id} href={`${profile_url}`}>
                    <a>
                      <Tippy
                        content={
                          <span>
                            {subscribe_price == 0.0
                              ? 'Free!'
                              : '$' + subscribe_price}
                          </span>
                        }
                      >
                        <img
                          src={`https://logo.clearbit.com/${site.url}`}
                          alt='creator'
                          className='dark:border-jacarta-600 hover:border-accent dark:hover:border-accent h-6 w-6 rounded-full border-2 border-white'
                        />
                      </Tippy>
                    </a>
                  </Link>
                )
              })}
            </div>
          </div>

          {/* Gender Icon */}
          {gender !== 'None' && (
            <div className='absolute right-3 -bottom-3'>
              <div className='flex -space-x-2'>
                <Tippy content={gender}>
                  <img
                    src={`/images/avatars/${
                      gender === 'Female' ? 'female' : 'male'
                    }.png`}
                    alt={gender}
                    className='dark:border-jacarta-600 hover:border-accent dark:hover:border-accent h-6 w-6 rounded-full border-2 border-white'
                  />
                </Tippy>
              </div>
            </div>
          )}
        </figure>

        {/* Model Name */}
        <div className='mt-7 flex items-center justify-between'>
          <Link legacyBehavior href={`/model/${username}`}>
            <a>
              <span className='font-display text-jacarta-700 hover:text-accent text-base dark:text-white'>
                {name}
              </span>
            </a>
          </Link>
        </div>

        {/* Model Tags */}
        <div className='mt-1 mb-3 flex flex-wrap items-center space-x-1 text-xs'>
          <span className='taglist text-accent flex inline-flex flex-wrap items-center space-x-1'>
            {tags_list?.slice(0, 2).map((t, i) => {
              return (
                <Link
                  key={t}
                  href={`/search/${t}`}
                  className='mr-1 hover:text-accent-lighter dark:hover:text-[#fff]'
                >
                  {t}
                  {i === 1 || tags_list.length === 1 ? '' : ','}
                </Link>
              )
            })}
          </span>
        </div>

        <div className='mt-8 flex items-center justify-between'>
          <Link
            className='cursor-pointer hidden text-accent font-display text-sm font-semibold'
            href={`/model/${username}`}
          >
            View Profile
          </Link>

          <span className='text-jacarta-400 dark:text-jacarta-200 text-sm'>
            {abbreviateNumber(views)} Views
          </span>
        </div>
      </div>
    </article>
  )
}
