import React, { useEffect, useRef, useState } from 'react'

import { placeholder, flash } from './placeholder.module.css'

export const Placeholder = () => {
  const phRef = useRef(null)

  const [{ spread, width }, setShadow] = useState({ spread: 0, width: 0 })

  useEffect(() => {
    const { clientWidth } = phRef.current

    setShadow({
      spread: `${clientWidth * 0.45}px`,
      width: `${clientWidth * 0.1}px`,
    })
  }, [])

  return (
    <div
      ref={phRef}
      className={`
        ${placeholder}
        h-full w-full 
        relative
        bg-jacarta-100
        dark:bg-[var(--darkmode-bg)]
        overflow-hidden
        `}
    >
      <div
        className={flash}
        style={{ boxShadow: `0 0 ${spread} ${width} #ffffff60` }}
      />
    </div>
  )
}
