import React, { useState } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import { useDispatch, useSelector } from 'react-redux'

import { HeadLine } from '../component'
import { BlocksLoader } from '../placeholder/blocks-loader'
import { startResultsLoading } from '../../redux/counterSlice'
import { abbreviateNumber } from '../../helpers/number-to-suffix'

const Browse_category = ({ data }) => {
  const { resultsLoading } = useSelector(({ counter }) => counter)
  const dispatch = useDispatch()

  const [hasLoader, setHasLoader] = useState(null)

  return (
    <div className='my-10'>
      <section className='relative h-full py-8'>
        <picture className='pointer-events-none absolute inset-0 -z-10 dark:hidden'>
          <Image
            src='/images/gradient_light.jpg'
            alt='gradient'
            className='h-full'
            fill
          />
        </picture>
        <HeadLine
          main
          text='Browse by tag'
          classes='font-display text-jacarta-700 mb-0 mt-6 text-center text-3xl dark:text-white'
        />

        <div className='relative'>
          <section className='relative px-6 pb-8 py-12'>
            <div className='flex flex-col gap-5 lg:flex-row'>
              <div className='w-full'>
                <div className='grid grid-cols-2 grid-rows-3 md:grid-cols-6 md:grid-rows-2 gap-5 [&>article:nth-child(n+9)]:hidden md:[&>article:nth-child(n+9)]:block'>
                  {data.map(
                    ({ name, icon: imgSrc, estimated_models_count }, idx) => {
                      return (
                        <article
                          key={name}
                          className='relative'
                          onClick={() => setHasLoader(idx)}
                        >
                          {hasLoader === idx && (
                            <div className='loading-shade z-10 absolute flex flex-col items-center justify-center rounded-2xl t-0 l-0 h-full w-full z-1 bg-[#ffffffee] dark:bg-[var(--darkmode-bg-tr)]'>
                              <BlocksLoader />
                              <span>Loading...</span>
                            </div>
                          )}
                          <div className='relative overflow-hidden rounded-2.5xl bg-white dark:bg-jacarta-700'>
                            <figure className='relative'>
                              <Link
                                legacyBehavior
                                href={`/search/${name || ''}`}
                              >
                                <a className='relative group block after:absolute after:inset-0 after:block after:bg-jacarta-900/20'>
                                  <img
                                    src={`${imgSrc}?class=featuredtagsdesk`}
                                    srcset={`
                                    ${imgSrc}?class=featuredtagsdesk 584w
                                    ${imgSrc}?class=featuredtagstab  264w
                                    ${imgSrc}?class=featuredtagsmob  378w, 
                                    `}
                                    sizes='
                                    (min-width: 1200px) 292px,
                                    (min-width: 768px) and (max-width: 1199px) 132px,
                                    (max-width: 767px) 189px'
                                    alt={name}
                                    className='aspect-square w-full object-cover transition-transform duration-[1600ms] will-change-transform group-hover:scale-105'
                                    height='470'
                                    width='470'
                                  />
                                </a>
                              </Link>
                            </figure>
                            <div className='pointer-events-none absolute bottom-0 w-full p-5'>
                              <h2 className='font-display text-base leading-none text-white xl:text-lg'>
                                {name}
                              </h2>
                              <span className='text-2xs text-white'>
                                {abbreviateNumber(estimated_models_count)}{' '}
                                models
                              </span>
                            </div>
                          </div>
                        </article>
                      )
                    }
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>

        <div className='w-full flex items-center justify-center my-6'>
          <Link
            href='/tags'
            className='relative text-accent font-bold mt-0 hover:text-accent-lighter '
            onClick={() => dispatch(startResultsLoading())}
          >
            <span className='bg-accent shadow-accent-volume hover:bg-accent-dark inline-block rounded-full py-3 px-8 text-center font-semibold text-white transition-all'>
              View all Tags
            </span>
            <span className='absolute left-1/2 top-1/2 -translate-x-1/2'>
              {resultsLoading && <BlocksLoader small />}
            </span>
          </Link>
        </div>
      </section>
    </div>
  )
}

export default Browse_category
