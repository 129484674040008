import { useRef, useState } from 'react'
import { Placeholder } from './placeholder/placeholder'

export const ImageWithPlaceholderFade = ({
  src,
  alt,
  round = false,
  rounded = false,
  showImage,
  srcSet = null,
  sizes = null,
  fallbackSrc = '',
}) => {
  const imgRef = useRef(null)
  const [imgSrc, setImgSrc] = useState(src)
  const [imgError, setImgError] = useState(true)
  const [fallbackAttempted, setfallbackAttempted] = useState(false)
  
  const handleLoad = () => {
    if (imgRef.current?.naturalHeight !== 0) {
      setImgError(false)
    }
  }

  const handleError = (e) => {
    if (fallbackAttempted) return
      setImgError(true)
      setImgSrc(fallbackSrc)
      setfallbackAttempted(true)
      e.target.onerror = null
  }

  return (
    <div
      className={` ${round ? 'rounded-full' : ''} ${rounded ? 'rounded-lg' : ''}
      absolute top-[-.5%] h-[101%] w-full overflow-hidden`}
    >
      {showImage && (
        <div
          className={`absolute top-[-.5%] l-0 h-[101%] w-full transition-opacity delay-200 duration-500 
          overflow-hidden
          `}
        >
          <img
            ref={imgRef}
            src={imgSrc}
            srcSet={imgError ? null : srcSet}
            sizes={imgError ? null : sizes}
            onError={handleError}
            onLoadedData={handleLoad}
            alt={alt}
            // loading='lazy'
            className='h-full w-full object-cover object-center'
          />
        </div>
      )}
      <div
        className={`z-[-1] absolute t-0 l-0 h-full w-full overflow-hidden
        `}
      >
        <Placeholder />
      </div>
    </div>
  )
}
