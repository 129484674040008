import React from 'react'

const HeadLine = ({
  text,
  classes,
  containerClasses = '',
  image,
  main = false,
}) => {

  const headerContent = (
    <>
      {image && (
        <img
          src={image}
          alt='heading'
          className={'mr-1 inline-block h-6 w-6 bg-contain bg-center text-xl'}
        />
      )}

      {text}
    </>
  )

  return (
    <div className={containerClasses}>
      {main ? (
        <h1 className={classes}>{headerContent}</h1>
      ) : (
        <h2 className={classes}>{headerContent}</h2>
      )}
    </div>
  )
}

export default HeadLine
